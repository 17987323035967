import { Component, OnInit } from '@angular/core';
import { EventService } from '../services/event.service';
import { Filter } from '../models/filter';
import { Event } from '../models/event';
import { map } from 'rxjs';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {
  
  eventList: Event[];
  isLoading: boolean = true;


  constructor(
      public eventsService: EventService) { }

  ngOnInit(): void {
    const filter:Filter = new Filter();
    filter.categories = ['GROUPS'];
    filter.fromDate = '01/08/2024'
    this.eventsService.getEvents(filter).pipe(
      map((graphqlEvents: any) =>
        graphqlEvents?.data?.events?.data?.length > 0 ? graphqlEvents.data.events.data.map(this.eventsService.transformEventDates) : []
      )
    ).subscribe((events: any) => {
      this.eventList = events;
      this.isLoading = false;
    });
  }

}
