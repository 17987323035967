<div class="navigation-wrapper" fxLayout="column" fxLayoutAlign="start center">
    <div class="navigation-toolbar full-width" fxLayout="row" fxLayoutAlign="space-between end">
        <div *ngIf="isLoading">{{'Loading' | translate}}...</div>
        <div fxLayout="row" fxLayoutAlign="start center" *ngIf="!isLoading" fxLayoutGap="5px">
            {{(eventList.length || 0) + " " + ('found' | translate)}}
        </div>
    </div>
</div>
<div *ngIf="isLoading" class="loading">
    <mat-spinner></mat-spinner>
</div>
<div class="wrapper event-list" #eventListContainer>
    <div class="list-container mat-elevation-z1" *ngIf="eventList && !isLoading">
        <div *ngIf="eventList.length == 0">
            <div class="no-events" translate>No events found with this criteria</div>
        </div>
        <div *ngIf="eventList.length > 0" fxLayout="column" fxLayoutAlign="start center">
            <app-event-list-item *ngFor="let event of eventList; let last = last; let i = index" [indexInList]="i"
                [event]="event" [last]="last" class="full-width"></app-event-list-item>
        </div>
    </div>
</div>