import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Booking, Ticket } from '../models/booking';
import { Event } from '../models/event';
import { Price } from '../models/price';
import { EventService } from '../services/event.service';
import { TicketTypeService } from '../services/ticket-type.service';

@Component({
  selector: 'app-ticket-selector',
  templateUrl: './ticket-selector.component.html',
  styleUrls: ['./ticket-selector.component.scss']
})
export class TicketSelectorComponent implements OnInit, OnDestroy, OnChanges {


  @Input()
  booking: Booking;

  @Input()
  eventId: string;

  @Input()
  venueName: string;

  @Input()
  startDateSeconds: number;

  @Input()
  tickets: Ticket[];

  @Input()
  inDoorCheckinMode: boolean = false;

  @Input()
  resetFormObservable: Observable<any>;

  @Input()
  toucher: Observable<any>;

  @Output()
  onTicketsChange: EventEmitter<any> = new EventEmitter();

  @Output()
  onValidityChange: EventEmitter<boolean> = new EventEmitter();

  event: Event;
  ticketsControl: FormArray;
  totalPrice: number = 0;
  currentNumberSelectedTickets: number = 0;
  numberTicketsCurrentBooking: number = 0;
  numAvailableTickets: number;
  updatedStatus: string;
  resetSubs: Subscription;
  sub: Subscription;

  constructor(
    public ticketTypeService: TicketTypeService,
    public eventService: EventService,) { }


  ngOnChanges(changes: SimpleChanges) {
    this.initialize();
  }

  ngOnInit(): void {
    this.initialize();
    this.sub = this.toucher?.subscribe(() => {
      this.ticketsControl?.markAllAsTouched();
      this.onValidityChange.emit(this.ticketsControl?.valid || false);
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    this.resetSubs?.unsubscribe();
  }

  //INIT
  initialize() {
    const unsub = this.eventService.getEventFromFirestore(this.booking?.eventId || this.eventId,
      (eventMayHaveRepetitions) => {
        if (eventMayHaveRepetitions) {
          unsub();
          if (!eventMayHaveRepetitions.repetitions || eventMayHaveRepetitions.repetitions?.length == 0) {
            this.event = eventMayHaveRepetitions;
          } else {
            for (let maybeRep of eventMayHaveRepetitions.repetitions) {
              console.log(maybeRep.venueObj?.name, this.booking?.venueName, maybeRep.startDate?.getTime(), this.booking?.startDate?.seconds);
              if (maybeRep.venueObj?.name == (this.booking?.venueName || this.venueName)
                && maybeRep.startDate?.getTime() == ((this.booking?.startDate?.seconds || this.booking?.startDate?._seconds || this.startDateSeconds) * 1000)) {
                this.event = { ...eventMayHaveRepetitions, ...maybeRep }
              }
            }
          }

          console.log(this.event)
          if (this.event) {
            this.resetSubs = this.resetFormObservable?.subscribe((_) => {
              this.ticketsControl = new FormArray(this.getEventTickets(this.event), { validators: this.validateTickets });
            });
            this.ticketsControl = new FormArray(this.getEventTickets(this.event), { validators: this.validateTickets });

            this.ticketsControl.valueChanges.subscribe((newTicketsValue) => {
              this.ticketsControl.markAsTouched();
              const newStatus = this.updatedStatus;
              this.onTicketsChange.emit({ newTicketsValue, newStatus });
              this.onValidityChange.emit(this.ticketsControl.valid);
            });

            //Add listeners to all the ticket types
            for (let i = 0; i < this.event.prices.length; i++) {
              this.ticketsControl.get([i]).get('numberTickets').valueChanges.subscribe(() => this.refreshTotalAmounts());
            }
            if (this.booking || this.tickets) {
              const prefilledTickets = this.booking?.tickets || this.tickets;
              this.numberTicketsCurrentBooking = prefilledTickets.reduce((prev, curr) => curr.numberTickets + prev, 0);
              for (let i = 0; i < this.event.prices.length; i++) {
                const prefilledTicket = prefilledTickets.find(t => t.type == this.event.prices[i].type);
                if (prefilledTicket) {
                  this.ticketsControl.get([i]).get('numberTickets').setValue(prefilledTicket.numberTickets);
                }
              }
            }
          }
        }
        //Listen realtime the number of tickets available
        this.updateAvailableTicketsAndStatus();
      }
    );
  }



  //VALIDATORS
  validateTickets(control: AbstractControl): { [key: string]: any } | null {
    if (control.value.length > 0) {
      const atLeastOneTicket: boolean = control.value.some((ticket) => ticket.numberTickets > 0);
      if (!atLeastOneTicket) {
        return { 'atLeastOneTicket': true };
      }
    }
    return null;
  }

  //HELPERS
  getEventTickets(event) {
    return event.prices.map(
      (price: Price) => new FormGroup({
        type: new FormControl(price.type, []),
        price: new FormControl(price.price, []),
        name_nb: new FormControl(price.name_nb, []),
        name_en: new FormControl(price.name_en, []),
        numberTickets: new FormControl(0)
      }));
  }
  getTicketTypeName(price: Price): string {
    return this.ticketTypeService.getTicketTypeName(price);
  }

  addATicket(e: any, i: number): void {
    e.preventDefault();
    const priceControl: AbstractControl = this.ticketsControl.get([i]);
    const currentTickets: number = priceControl.get('numberTickets').value;
    priceControl.get('numberTickets').setValue(currentTickets + 1);
  }

  removeATicket(e: any, i: number): void {
    e.preventDefault();
    const priceControl: AbstractControl = this.ticketsControl.get([i]);
    const currentTickets = priceControl.get('numberTickets').value;
    if (currentTickets >= 1) {
      priceControl.get('numberTickets').setValue(currentTickets - 1);
    }
  }


  updateAvailableTicketsAndStatus(): void {
    if (this.event) {
      if (this.booking?.status == 'waiting_list') {
        if (this.currentNumberSelectedTickets > (this.event.availableTickets - this.event.activeTickets)) {
          this.numAvailableTickets = this.event.availableTickets - this.event.activeTickets;
          this.updatedStatus = 'waiting_list';
        } else {
          this.numAvailableTickets = this.event.availableTickets - this.event.activeTickets - this.currentNumberSelectedTickets;
          this.updatedStatus = 'active';
        }
      }
      if (this.booking?.status == 'active') {
        //If number of selected tickets is less than previous, it's keep in active status
        //If active tickets did not reach the number of ticket available, keep it in active
        //Otherwise change it to waiting_list
        if ((this.currentNumberSelectedTickets - this.numberTicketsCurrentBooking) > (this.event.availableTickets - this.event.activeTickets) &&
        (this.currentNumberSelectedTickets > this.numberTicketsCurrentBooking)) {
          this.numAvailableTickets = this.event.availableTickets - this.event.activeTickets;
          this.updatedStatus = 'waiting_list';
        } else {
          this.numAvailableTickets = this.event.availableTickets - this.event.activeTickets - this.currentNumberSelectedTickets + this.numberTicketsCurrentBooking;
          this.updatedStatus = 'active';
        }
      }
      if (!this.booking && this.event) {
        this.numAvailableTickets = this.event.availableTickets - this.event.activeTickets - this.currentNumberSelectedTickets;
        this.updatedStatus = this.numAvailableTickets >= 0 ? 'active' : 'waiting_list';
      }
    }
  }

  refreshTotalAmounts(): void {
    this.totalPrice = 0;
    this.currentNumberSelectedTickets = 0;
    for (let i = 0; i < this.event.prices.length; i++) {
      const ticket = this.ticketsControl.get([i]);
      if (ticket) {
        this.totalPrice += ticket.get("price").value * ticket.get('numberTickets').value;
        this.currentNumberSelectedTickets += ticket.get('numberTickets').value;
      }
    }
    this.updateAvailableTicketsAndStatus();
  }
}
