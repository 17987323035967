import { Component, OnInit } from '@angular/core';
import { EventService } from '../services/event.service';
import { Filter } from '../models/filter';
import { map } from 'rxjs';

@Component({
  selector: 'app-parent-meetings',
  templateUrl: './parent-meetings.component.html',
  styleUrls: ['./parent-meetings.component.scss']
})
export class ParentMeetingsComponent implements OnInit {

  eventList: Event[];
  isLoading: boolean = true;


  constructor(
    public eventsService: EventService) { }

  ngOnInit(): void {
    const filter: Filter = new Filter();
    filter.categories = ['MEETINGS'];
    filter.fromDate = '01/08/2024'
    this.eventsService.getEvents(filter).pipe(
      map((graphqlEvents: any) =>
        graphqlEvents?.data?.events?.data?.length > 0 ? graphqlEvents.data.events.data.map(this.eventsService.transformEventDates) : []
      )
    ).subscribe((events: any) => {
      this.eventList = events;
      this.isLoading = false;
    });
  }

}
