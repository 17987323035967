import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EventListComponent } from './event-list/event-list.component';
import { EventSingleComponent } from './event-single/event-single.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AccountComponent } from './account/account.component';
import { EventEditionComponent } from './event-edition/event-edition.component';
import { FeedComponent } from './feed/feed.component';
import { WhyUseComponent } from './why-use/why-use.component';
import { AdvertisingComponent } from './advertising/advertising.component';
import { WhatIsComponent } from './what-is/what-is.component';
import { ContactComponent } from './contact/contact.component';
import { MyEventsComponent } from './my-events/my-events.component';
import { MyTeamsComponent } from './my-teams/my-teams.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { AdminComponent } from './admin/admin.component';
import { LoginScreenComponent } from './login-screen/login-screen.component';
import { AuthGuard } from './guards/auth.guard';
import { UnsubscribeEmailComponent } from './unsubscribe-email/unsubscribe-email.component';
import { ImageGuidelinesComponent } from './image-guidelines/image-guidelines.component';
import { PublishingRulesComponent } from './publishing-rules/publishing-rules.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { CampaignComponent } from './campaign/campaign.component';
import { MaybeOnlyRegisteredGuard } from './guards/maybe-only-registered.guard';
import { BookingsListComponent } from './bookings-list/bookings-list.component';
import { EditTeamComponent } from './edit-team/edit-team.component';
import { WarningsComponent } from './warnings/warnings.component';
import { TermsAndConditionsRSVPComponent } from './terms-and-conditions-rsvp/terms-and-conditions-rsvp.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { CustomersComponent } from './customers/customers.component';
import { CustomerEditionComponent } from './customer-edition/customer-edition.component';
import { AdminBookingComponent } from './admin-booking/admin-booking.component';
import { FrontpageComponent } from './frontpage/frontpage.component';

import { environment } from '../environments/environment';
import { MoreComponent } from './more/more.component';
import { MyFavoritesComponent } from './my-favorites/my-favorites.component';
import { MyTicketsPageComponent } from './my-tickets-page/my-tickets-page.component';
import { CodeProtectionGuard } from './guards/code-protection.guard';
import { EnterCodeComponent } from './enter-code/enter-code.component';
import { GroupsComponent } from './groups/groups.component';
import { ParentMeetingsComponent } from './parent-meetings/parent-meetings.component';

const appRoutes: Routes = [
  {
    path: 'enter-code',
    component: EnterCodeComponent
  },
  {
    path: '',
    component: environment.theme == 'trdevents' ? FrontpageComponent : EventListComponent,
    canActivate: [CodeProtectionGuard, MaybeOnlyRegisteredGuard]
  },
  {
    path: 'event/:slug',
    component: EventSingleComponent,
    canActivate: [CodeProtectionGuard, MaybeOnlyRegisteredGuard]
  },
  {
    path: 'category/:categories',
    component: environment.theme == 'trdevents' ? FrontpageComponent : EventListComponent,
    canActivate: [CodeProtectionGuard, MaybeOnlyRegisteredGuard],
    data: {
      type: 'navigational'
    },
  },
  {
    path: 'search/:searchTerm',
    component: environment.theme == 'trdevents' ? FrontpageComponent : EventListComponent,
    data: {
      type: 'navigational'
    },
    canActivate: [CodeProtectionGuard, MaybeOnlyRegisteredGuard]
  },
  {
    path: 'fromDate/:fromDate',
    component: environment.theme == 'trdevents' ? FrontpageComponent : EventListComponent,
    data: {
      type: 'navigational'
    },
    canActivate: [CodeProtectionGuard, MaybeOnlyRegisteredGuard]
  },
  //VM-FEST START
  {
    path: 'vm-fest',
    component: environment.theme == 'trdevents' ? FrontpageComponent : EventListComponent,
    canActivate: [CodeProtectionGuard, MaybeOnlyRegisteredGuard],
    data: {
      custom: 'vm-fest'
    }
  },
  {
    path: 'vm-fest/event/:slug',
    component: EventSingleComponent,
    canActivate: [CodeProtectionGuard, MaybeOnlyRegisteredGuard],
    data: {
      custom: 'vm-fest'
    }
  },
  {
    path: 'vm-fest/category/:categories',
    component: environment.theme == 'trdevents' ? FrontpageComponent : EventListComponent,
    data: {
      custom: 'vm-fest',
      type: 'navigational'
    },
    canActivate: [CodeProtectionGuard, MaybeOnlyRegisteredGuard]
  },
  {
    path: 'vm-fest/search/:searchTerm',
    component: environment.theme == 'trdevents' ? FrontpageComponent : EventListComponent,
    data: {
      type: 'navigational',
      custom: 'vm-fest'
    },
    canActivate: [CodeProtectionGuard, MaybeOnlyRegisteredGuard]
  },
  {
    path: 'vm-fest/favorites',
    component: MyFavoritesComponent,
    data: {
      type: 'navigational',
      custom: 'vm-fest'
    },
    canActivate: [CodeProtectionGuard, MaybeOnlyRegisteredGuard]
  },
  //VM-FEST END
  {
    path: 'filter',
    component: environment.theme == 'trdevents' ? FrontpageComponent : EventListComponent,
    canActivate: [CodeProtectionGuard, MaybeOnlyRegisteredGuard]
  },
  {
    path: 'favorites',
    component: MyFavoritesComponent,
    data: {
      type: 'navigational'
    },
    canActivate: [CodeProtectionGuard, MaybeOnlyRegisteredGuard]
  },
  {
    path: 'venue/:venueSlug',
    component: environment.theme == 'trdevents' ? FrontpageComponent : EventListComponent,
    data: {
      type: 'navigational'
    },
    canActivate: [CodeProtectionGuard, MaybeOnlyRegisteredGuard]
  },
  {
    path: 'organizer/:organizerSlug',
    component: environment.theme == 'trdevents' ? FrontpageComponent : EventListComponent,
    data: {
      type: 'navigational'
    },
    canActivate: [CodeProtectionGuard, MaybeOnlyRegisteredGuard]
  },
  {
    path: 'mode/:mode',
    component: environment.theme == 'trdevents' ? FrontpageComponent : EventListComponent,
    data: {
      type: 'navigational'
    },
    canActivate: [CodeProtectionGuard, MaybeOnlyRegisteredGuard]
  },
  {
    path: 'tag/:tag',
    component: environment.theme == 'trdevents' ? FrontpageComponent : EventListComponent,
    data: {
      type: 'navigational'
    },
    canActivate: [CodeProtectionGuard, MaybeOnlyRegisteredGuard],
  },
  {
    path: 'login',
    component: LoginScreenComponent
  },
  {
    path: 'add-an-event',
    component: EventEditionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-event/:id',
    component: EventEditionComponent,
    canActivate: [AuthGuard],
    data: {
      isEdit: true
    }
  },
  {
    path: 'my-events',
    component: MyEventsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'my-events/past',
    component: MyEventsComponent,
    canActivate: [AuthGuard],
    data: {
      pastEvents: true
    }
  },
  {
    path: 'my-teams',
    component: MyTeamsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-team/:teamId',
    component: EditTeamComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'warnings',
    component: WarningsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'campaigns',
    component: CampaignsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bookings',
    component: BookingsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bookings/new',
    component: AdminBookingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'customers',
    component: CustomersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'customers/new',
    component: CustomerEditionComponent,
    canActivate: [AuthGuard],
    data: {
      isEdit: false
    }
  },
  {
    path: 'customers/edit/:customerId',
    component: CustomerEditionComponent,
    canActivate: [AuthGuard],
    data: {
      isEdit: true
    }
  },
  {
    path: 'my-tickets',
    component: MyTicketsPageComponent
  },
  {
    path: 'view-campaign/:campaignId',
    component: CampaignComponent,
    canActivate: [AuthGuard],
    data: {
      isEdit: false
    }
  },
  {
    path: 'edit-campaign/:campaignId',
    component: CampaignComponent,
    canActivate: [AuthGuard],
    data: {
      isEdit: true
    }
  },
  {
    path: 'why',
    component: WhyUseComponent,
  },
  {
    path: 'organizers',
    component: MoreComponent,
    children: [
      {
        path: 'my-events',
        component: MyEventsComponent,
        outlet: 'organizers'
      },
      {
        path: 'add-an-event',
        component: EventEditionComponent,
        outlet: 'organizers'
      },
      {
        path: 'my-teams',
        component: MyTeamsComponent,
        outlet: 'organizers'
      },
      {
        path: 'login',
        component: LoginScreenComponent,
        outlet: 'organizers'
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        outlet: 'organizers'
      },
      {
        path: 'advertising',
        component: AdvertisingComponent,
        outlet: 'organizers'
      },
      {
        path: 'what',
        component: WhatIsComponent,
        outlet: 'organizers'
      },
      {
        path: 'why',
        component: WhyUseComponent,
        outlet: 'organizers'
      },
      {
        path: 'about-us',
        component: AboutUsComponent,
        outlet: 'organizers'
      }
    ]
  },
  {
    path: 'advertising',
    component: AdvertisingComponent,
  },
  {
    path: 'what',
    component: WhatIsComponent,
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
  },
  {
    path: 'feed',
    component: FeedComponent,
  },
  {
    path: 'newsletter',
    component: NewsletterComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'guidelines-pictures',
    component: ImageGuidelinesComponent,
  },
  {
    path: 'publishing-rules',
    component: PublishingRulesComponent,
  },
  {
    path: 'contact-form',
    component: ContactComponent,
  },
  {
    path: 'unsubscribeEmail',
    component: UnsubscribeEmailComponent,
  },
  {
    path: 'terms-and-conditions-rsvp',
    component: TermsAndConditionsRSVPComponent,
  },
  //SKOLEAGENDA
  {
    path: 'grupper',
    component: GroupsComponent,
    canActivate: [CodeProtectionGuard]
  },
  {
    path: 'foreldremote',
    component: ParentMeetingsComponent,
    canActivate: [CodeProtectionGuard]
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
      anchorScrolling: 'enabled',
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
