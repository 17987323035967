<div class="wrapper" [ngClass]="environment.theme == 'trdevents' ? 'trdevents' : 'classic'">
  <div class="page mat-elevation-z1">
    <div *ngIf="translate.currentLang != 'nb'">
      <h1 class="mat-h1">Why use "{{environment.content.siteNameTranslatable.en}}"</h1>
      <div class="mat-body">
        <p *ngIf="environment.theme == 'classic'">"{{environment.content.siteNameTranslatable.en}}" is a gratis service for the promotion of events in {{environment.siteLocation}}.<p>
        <p *ngIf="environment.theme == 'trdevents'">"{{environment.content.siteNameTranslatable.nb}}" is Trondheim's largest event listing. It is FREE to register events and you reach a large audience.<p>
            <p>Organizers can register and update their own events</p>
            <h4 class="mat-h4">As an organizer in "{{environment.content.siteNameTranslatable.en}}" you can:</h4>
            <ul>
              <li>Register your events totally free</li>
              <li>Schedule multiple instance of your event (event repetitions)</li>
              <li>Set up a festival with events within it</li>
              <li>We share your event in the "{{environment.content.facebookpage || environment.content.siteNameTranslatable.nb}}" facebook page</li>
              <li>Show your event on the newsletter that it's automatically sent every week, totally free</li>
            </ul>
            <p><a class="blue" routerLink="/add-an-event">Add an event here</a></p>
      </div>
    </div>
    <div *ngIf="translate.currentLang == 'nb'">
      <h1 class="mat-h1">Hvorfor benytte "{{environment.content.siteNameTranslatable.nb}}"</h1>
      <div class="mat-body">
        <p *ngIf="environment.theme == 'classic'">"{{environment.content.siteNameTranslatable.nb}}" er en kostnadsfri tjeneste for markedsføring av arrangementer i {{environment.siteLocation}}.<p>
        <p *ngIf="environment.theme == 'trdevents'">"{{environment.content.siteNameTranslatable.nb}}" er Trondheims største arrangementsoversikt. Det er GRATIS å registrere arrangement og du når ut til et stort publikum.<p>
            <p>Som arrangør registrerer du dine arrangementer selv, og har muligheten til å redigere eller oppdatere fortløpende.</p>
            <h4 class="mat-h4">Som arrangør på "{{environment.content.siteNameTranslatable.nb}}" kan du:</h4>
            <ul>
              <li>Registrere dine arrangement helt kostnadsfritt</li>
              <li>Opprette et repeterende arrangement</li>
              <li>Opprette en festival med tilhørende arrangementer</li>
              <li>På Facebooksiden "{{environment.content.facebookpage || environment.content.siteNameTranslatable.nb}}" legges daglig ut utvalgte arrangement.</li>
              <li>Synlig på Visit Trondheim - Hva skjer i Trondheim.</li>
              <li>Bli med på nyhetsbrevet som sendes ut ukentlig helt kostnadsfritt</li>
            </ul>
            <p><a class="blue" routerLink="/add-an-event">Legg til et arrangement her</a></p>
            <p>TIPS! Vi tilbyr annonseplass for arrangører i det ukentlige nyhetsbrevet. Se annonsering på nyhetsbrev.</p>
      </div>
    </div>
  </div>
</div>
