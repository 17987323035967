<div #top></div>
<div class="full-width message waiting-list-warning" *ngIf="show != 'booking-success' && goesToWaitingList">
    {{'Your order is being placed on a waiting list because there are others ahead of you in the queue. If you have any questions, please contact Seniorkultur at 90 19 27 14. Our phone number is open on weekdays from 12:00-15:00.' | translate}}
</div>

<form fxLayout="column" class="full-width" fxLayoutGap="30px" #bookingFormEle *ngIf="show != 'booking-success'">

    <app-booking-event-selector *ngIf="event?.repetitions?.length > 0" [eventFormControl]="eventFormControl" (onEventsLoaded)="onEventsLoaded($event)" 
        [filter]="{eventID: event?.id}" [showRangeFilter]="false"
        [toucher]="toucher" [showAllMyEventsOption]="false" [hint]="'Select a date and venue' | translate"></app-booking-event-selector>

    <div fxLayout="row" fxLayout.lt-lg="column" class="full-width" fxLayoutAlign="start start" fxLayoutGap="30px">
        <app-customer-name-editor fxFlex="grow" fxFlex.xs="auto" fxFill (onNameChange)="onCustomerNameChange($event)" 
        (onValidityChange)="validityOnComponent($event, 1)" [toucher]="toucher" [name]="prefilledName"></app-customer-name-editor>
        <app-customer-phone-editor fxFlex="300px" fxFlex.xs="auto" fxFill 
        [eventId]="selectedInstance?.id || event.id" 
        [eventStartDateSeconds]="event.repetitions?.length > 0 ? selectedInstance?.startDate?.seconds : event.startDate?.seconds"
        [eventVenueName]="event.repetitions?.length > 0 ? (selectedInstance?.venue?.name || selectedInstance?.venueObj?.name) : (event.venue?.name || event.venueObj?.name)" 
        (onPhoneChange)="onCustomerPhoneChange($event)" [toucher]="toucher"
        [phone]="prefilledPhone"
        (onValidityChange)="validityOnComponent($event, 2)"></app-customer-phone-editor>
    </div>

    <app-customer-address-editor *ngIf="addressCompulsory" [toucher]="toucher" [address]="prefilledAddress"
        (onCustomerAddressChange)="onCustomerAddressChange($event)"
        [isRequired]="addressCompulsory" (onValidityChange)="validityOnComponent($event, 3)"></app-customer-address-editor>

    <app-customer-comments-editor (onValidityChange)="validityOnComponent($event, 4)"
        (onCustomerCommentsChange)="onCustomerCommentsChange($event)" [comments]="prefilledComments"></app-customer-comments-editor>

    <app-ticket-selector *ngIf="event?.repetitions?.length == 0 || selectedInstance" class="full-width margin-top"
        [eventId]="selectedInstance?.id || event.id"
        [startDateSeconds]="selectedInstance?.startDate?.seconds || event.startDate?.seconds" [toucher]="toucher"
        [venueName]="selectedInstance?.venueObj?.name || event.venueObj?.name"
        (onTicketsChange)="onTicketsChange($event)"
        (onValidityChange)="validityOnComponent($event, 5)"></app-ticket-selector>

    <div fxLayout="column" class="full-width" fxLayoutGap="20px">
        <ul class="full-width important-notes">
            <li>{{'Payment method' | translate }}: <strong>{{(selectedInstance || event).paymentMethod == 'invoice' ? ('Invoice' |
                    translate) : ('At the door' | translate)}}</strong></li>
            <li>{{'Cancellation deadline' | translate }}: <strong>{{ eventService.getCancellationDeadline(event, (selectedInstance || event)?.startDate) |
                date:'short':'CET':translate.currentLang }}</strong></li>
            <li>{{'By booking these tickets, I accept the' | translate }} <a class="blue"
                    href="/terms-and-conditions-rsvp" target="_blank">{{'terms and conditions' | translate }}</a>
            </li>
        </ul>
    </div>

    <div fxLayout="row" class="full-width" fxLayoutAlign="end end">
        <div fxLayout="row" fxLayoutGap="10px">
            <button mat-raised-button color="primary" (click)="bookTickets()">{{ goesToWaitingList ? ('Apply to waiting
                list' | translate ): ('Book tickets' | translate) }}</button>
        </div>
    </div>

</form>

<div class="full-width message success-booking" *ngIf="show == 'booking-success'">
    {{'Your tickets were successfully booked' | translate}}. {{'We have sent you an SMS with the confirmation' |
    translate}}. {{ environment.contact?.phone ? ('If you have any questions, you can call us at' |
    translate) + ": " + environment.contact?.phone + ".": ''}}
</div>